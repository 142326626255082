import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>import('./my-queue-detail/my-queue-detail-routing.module').then(m => m.MyQueueDetailPageRoutingModule)
  },
  {
    path: 'home/:id',
    loadChildren: () =>import('./my-queue-detail/my-queue-detail-routing.module').then(m => m.MyQueueDetailPageRoutingModule)
  },

  {
    path: 'ready',
    loadChildren: () => import('./ready/ready.module').then(m => m.ReadyPageModule)
  },
  {
    path: 'edit-data',
    loadChildren: () => import('./edit-data/edit-data.module').then(m => m.EditDataPageModule)
  },
  {
    path: 'q/:code',
    loadChildren: () => import('./q/q.module').then(m => m.QPageModule)
  },
  {
    path: 'myqueue',
    loadChildren: () => import('./my-queue-detail/my-queue-detail-routing.module').then(m => m.MyQueueDetailPageRoutingModule)
  },
  {
    path: 'c/:code',
    loadChildren: () => import('./c/c.module').then( m => m.CPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'r/:code',
    loadChildren: () => import('./reservation/reservation.module').then( m => m.ReservationPageModule)
  },  {
    path: 'd-all',
    loadChildren: () => import('./d-all/d-all.module').then( m => m.DAllPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
