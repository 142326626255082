import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { WebapiServiceProvider } from '../providers/webapi-service/webapi-service';

@Component({
  selector: 'app-ready',
  templateUrl: './ready.page.html',
  styleUrls: ['./ready.page.scss'],
})
export class ReadyPage implements OnInit {
  public data = {
    count: 1,
    name: '',
    date_time: "2020-06-08 20:26:53",
    people: 1,
    qcode: "Q001",
    status: 0,
    table: "",
    q_id: '',
    url: ""
  };
  public store = {
    code: "",
    cus_cancel: true,
    fb: "",
    name: "Deltafood",
    order_id: 0,
    id_res_auto: '0',
    qrcode_text: { buttom: '' },
    logo_url: 'member_img/member_1678936883_.jpg',
    website: 'https://deltafood.me'
  };
  isload = false;
  constructor(public afd: AngularFireDatabase,
    public alertController: AlertController,
    private ref: ChangeDetectorRef,
    public api: WebapiServiceProvider,
    public router: Router) { }
  ngOnInit() {
    // alert("xxx");
  }
  ionViewWillEnter() {
    let queeu_data: any = localStorage.getItem("queue_data");

    if (queeu_data) {
      queeu_data = JSON.parse(queeu_data);
      console.log(queeu_data);
      this.api.getData("get_store/" + queeu_data.code).then((store: any) => {
        console.log(store);
        if (store.flag) {
          this.store = store;
          // this.load();
          this.afd.database.ref(this.store.fb + "/queue").on("value", (val) => {
            this.load(this.store.id_res_auto);
          });
        } else {
          window.location.href = this.store.website;
        }
      });
    }

  }

  open_table() {
    localStorage.removeItem("queue");
    window.location.href = this.data.url;
  }
  load(id_res_auto): void {
    let q_id = localStorage.getItem("queue");
    if (!q_id) {
      window.location.href = this.store.website;
    }
    this.api.getData("get_queue/" + q_id + '/' + id_res_auto).then((result: any) => {
      this.data = result;
      if (this.data.status - 0 == 0) {
        ///
        // window.location.href = "https://deltafood.me";
        this.router.navigate(['/home']);
      } else if (this.data.status - 0 === 1) {

        // localStorage.removeItem("queue");
        // window.location.href = "https://deltafood.me";

      } else if (this.data.status - 0 == 2) {
        this.cancel();
      } else if (this.data.status - 0 === 3) {
      } else if (this.data.status - 0 == 4) {
        this.error();
      }
      setTimeout(() => {
        this.ref.detectChanges();
      }, 100);
    });
  }
  async error() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คิวของคุณได้ผ่านมาแล้ว ไม่สามารถใช้งานได้',
      subHeader: 'กรุณาสแกนเพื่อจองคิวใหม่',
      // message: 'This is an alert message.',
      buttons: ['OK']
    });
    await alert.present();
  }
  async cancel() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คิวของคุณถูกยกเลิกแล้ว',
      subHeader: 'กรุณาสแกนเพื่อจองคิวใหม่',
      // message: 'This is an alert message.',
      buttons: ['OK']
    });
    setTimeout(() => {
      localStorage.removeItem("queue");
      setTimeout(() => {
        window.location.href = this.store.website;
      }, 100);

    }, 5000);
    await alert.present();
  }
  async cancel_q() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คุณต้องการยกเลิกคิวของคุณหรือไม่',
      // subHeader: 'Subtitle',
      // message: 'This is an alert message.',
      buttons: [
        {
          text: 'ปิดออก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'ยกเลิกคิว',
          handler: (value) => {
            let q = localStorage.getItem("queue");
            this.api.postData("cancel", { q: q }).then(() => {
              // this.afd.database.ref(this.store.fb + "/queue").set(Math.floor(Math.random() * 100) + 1);
              this.afd.database.ref(this.store.fb + "/noti").set(Math.floor(Math.random() * 100) + 1);
              this.cancel_success();

            })


          }
        }
      ]
    });

    await alert.present();
  }
  async cancel_success() {
    localStorage.removeItem("queue");
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'โอกาสหน้าแวะมาใหม่นะคะ',
      // subHeader: 'Subtitle',
      // message: 'This is an alert message.',
      buttons: [
        {
          text: 'ปิดออก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            window.location.href = this.store.website;
          }
        }
      ]
    });
    setTimeout(() => {
      window.location.href = this.store.website;
    }, 5000);
    await alert.present();
  }

}
