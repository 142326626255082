import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { WebapiServiceProvider } from '../providers/webapi-service/webapi-service';
@Component({
  selector: 'app-edit-data',
  templateUrl: './edit-data.page.html',
  styleUrls: ['./edit-data.page.scss'],
})
export class EditDataPage implements OnInit {
  people: number = 1;
  data = {
    name: '',
    phone: '',
    people: this.people
  };
  code = '';
  id_res_auto = '';

  uuid: any;
  constructor(public route: ActivatedRoute, public navParams: NavParams, private alertController: AlertController, public api: WebapiServiceProvider, public viewCtrl: ModalController) {

    this.code = this.navParams.data.code;
    this.data = navParams.data.data;
    this.people = this.data.people;
    this.id_res_auto = navParams.data.id_res_auto;
  }

  ngOnInit() {
    // localStorage.setItem("queue", '1234');



  }
  close() {
    this.viewCtrl.dismiss();
  }
  onWillDismiss(e) {

  }
  async presentAlert(text: string) {
    const alert = await this.alertController.create({
      header: text,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }
  async confirm_booking(data) {
    const alert = await this.alertController.create({
      header: 'ยืนยันการจอง',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'ปิดออก',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'จองเลย',
          cssClass: 'alert-button-confirm',
          handler: () => {
            console.log(data);
            this.api.postData('add_queue', data).then((res: any) => {
              console.log(res);
              this.api.update_json('queue', this.id_res_auto);
              if (res != '0') {
                localStorage.setItem("queue", res);
              }
              let temp = JSON.parse(JSON.stringify(data));
              delete temp.people;
              delete temp.q_id;
              localStorage.setItem("queue_data", JSON.stringify(temp));
              this.viewCtrl.dismiss();
            }, () => {
              this.api.update_json('queue', this.id_res_auto);
            })
            // this.api.postData("add_queue", data).then((res: any) => {
            //   console.log(res);
            //   if (res != '0') {
            //     localStorage.setItem("queue", res);
            //   }
            //   let temp = JSON.parse(JSON.stringify(data));
            //   delete temp.people;
            //   delete temp.q_id;
            //   localStorage.setItem("queue_data", JSON.stringify(temp));
            // })

            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            // localStorage.setItem("queue", '77484');
          },
        },
      ],
    });

    await alert.present();

  }
  confirm(data) {
    data.people = this.people;
    data.code = this.code;

    if (data.name == '') {
      this.presentAlert("กรุณาระบุชื่อของคุณ");
      return;
    } else if (data.phone == '') {
      this.presentAlert("กรุณากรอกเบอร์โทรศัพท์");
      return;
    }
    this.confirm_booking(data);
  }
  cancel() {

  }

  add() {
    this.people = this.people + 1;
  }
  remove() {
    if (this.people > 1) {
      this.people--;
    }
  }
  name = '';

}
