// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: "https://backend.deltafood.me/queue/",
  // url: "http://192.168.1.160:4000/queue/",
  // url: "https://orderapi.deltafood.me/queue/"
  // apiUrl: 'http://192.168.1.160:4000/reservations-web/',
  // apiInsertQueue:'http://192.168.1.160:4000/'
  // url: 'https://backend.deltafood.me/queue/',
  // apiUrl: 'https://backend.deltafood.me/reservations-web/',
  // apiInsertQueue:'https://backend.deltafood.me/',
  // url: 'http://localhost:4000/queue/',
  url: 'https://dqbackend.deltafood.me/queue/',
  apiUrl: 'https://dqbackend.deltafood.me/reservations-web/',
  apiInsertQueue:'https://dqbackend.deltafood.me/',
  del:'https://dqbackend.deltafood.me/',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 