import { GlobalProvider } from './providers/global/global';
import { WebapiServiceProvider } from './providers/webapi-service/webapi-service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReadyPage } from './ready/ready.page';
import { ReadyPageModule } from './ready/ready.module';
import { IonicStorageModule } from '@ionic/storage';
import { ModalPagePage } from './modal-page/modal-page.page';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { HomePageModule } from './home/home.module';
import { MyQueueDetailPage } from './my-queue-detail/my-queue-detail.page';
import { ReservationPageModule } from './reservation/reservation.module';
export const firebaseConfig = {
  apiKey: "AIzaSyCgw5Jtyam9L-t9uuIeudXuvPtJMP1UoWU",
  authDomain: "panda-restaurant.firebaseapp.com",
  databaseURL: "https://panda-restaurant.firebaseio.com",
  projectId: "panda-restaurant",
  storageBucket: "panda-restaurant.appspot.com",
  messagingSenderId: "1026422127449",
  appId: "1:1026422127449:web:8e89f9219ac3fb5c8c2a48"
};
export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, ModalPagePage,MyQueueDetailPage],
  entryComponents: [ReadyPage, ModalPagePage,MyQueueDetailPage],
  imports: [BrowserModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }

    }),
    HomePageModule,
    FormsModule,
    ReservationPageModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    ReadyPageModule,
    AppRoutingModule],
  providers: [
    StatusBar,
    WebapiServiceProvider,
    GlobalProvider,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
