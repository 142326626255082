import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalProvider {

  // local
  // public baseUrl: string = "http://192.168.1.28/thaismartjob/assets/backend/images/user_avatar/";
  // public baseUrlApi: string = "http://192.168.1.28/thaismartjob/api/v1/client/";

  public baseUrlApi: string = environment.url;

  public msgStatusCodeAPI_0 = "Error status Code:0 Web API Offline";

  constructor(private toast: ToastController, private translate: TranslateService) {
    // 
  }
  url() {
    return this.baseUrlApi;
  }
  presentToast() {
    this.translate.get('not_internet').subscribe(not_internet => {
      this.presentToast2(not_internet);
    });
  }
  async presentToast2(not_internet) {
    const toast = await this.toast.create({
      message: not_internet,
      duration: 3000
    });
    toast.present();
  }
  noNetworkConnection() {
    this.presentToast();
  }

  // production
  // public baseUrlApi:string = "https://thaismartjob.com/api/v1/client/";

}
