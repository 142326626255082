// import { Http, Headers } from '@angular/http';
// import { HttpClient, HttpHeaders, HttpErrorResponse,HttpRequest } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { GlobalProvider } from '../global/global';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class WebapiServiceProvider {
  constructor(
    private toast: ToastController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public http: HttpClient,
    private storage: Storage,
    private translate: TranslateService,
    public firebase: AngularFireDatabase,
    private global: GlobalProvider) {
    //
  }
  url() {
    return this.global.url();
  }
  async presentAlert(header, message) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
  async presentToast() {
    const toast = await this.toast.create({
      message: this.global.msgStatusCodeAPI_0,
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }
  update_json(table: string, id_res_auto) {
    // console.log(table);
    $.get('https://imgs.deltafood.me/Gen_json/index/' + table + '/' + id_res_auto);
    // this.http.get('https://imgs.deltafood.me/Gen_json/index/' + table + '/' + data.id_res_auto);
  }
  async Toast(text) {
    const toast = await this.toast.create({
      message: text,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
  on_loading() {
    this.translate.get('wait').subscribe(wait => {
      this.on_loading2(wait);
    });
  }
  async on_loading2(wait) {
    let loading = await this.loadingCtrl.create({
      message: wait,
      spinner: 'crescent',
      duration: 2000
    });
    return await loading.present();
  }
  // POST Method
  post(segment, objdata) {
    return new Promise((resolve, reject) => {
      this.storage.get('AppLangcode').then((result) => {
        if (!result) {
          result = "th";
        }
        result = 'th';
        let headers = new Headers();
        this.http.post(this.global.baseUrlApi + segment + "?lang=" + result, objdata, {
          headers: new HttpHeaders().append('Accept', 'application/json').append('Content-Type', 'application/json')
        })
          .subscribe((res: any) => {
            if (res.ok) {
              resolve(JSON.parse(res['_body']));
            } else {
              reject(res.statusText);
            }
          }, (err) => {
            if (err.status == 0) {
              this.presentToast();
              reject(err);
            }
          });
      });
    });
  }

  post_new(segment, objdata) {
    return this.http.post(this.global.baseUrlApi + segment + "?lang=", JSON.stringify(objdata));
  }
  postData(segment, objdata) {
    return new Promise((resolve, reject) => {

      this.storage.get('AppLangcode').then((result) => {
        if (!result) {
          result = "th";
        }
        result = 'th';
        this.http.post(this.global.baseUrlApi + segment + "?lang=" + result, JSON.stringify(objdata), {
          headers: new HttpHeaders().append('Accept', 'application/json').append('Content-Type', 'application/json')
        })
          .subscribe((res: any) => {
            resolve(res);

          }, (err) => {
            this.presentToast();
            reject(err);
          });
      });
    });
  }
  // GET METHOD
  fb_value(child, func) {
    this.storage.get('data').then((data: any) => {
      if (data) {
        let a = this.firebase.database
          .ref(data.id_res_auto + "_" + data.code + "/" + child).on("value", func);

      }

    });
  }

  fb_set(child) {
    this.storage.get('data').then((data: any) => {
      if (data) {
        this.firebase.database
          .ref(data.id_res_auto + "_" + data.code + "/" + child).set(Math.random());
      }
    });
  }
  fb_set_val(child, val) {
    this.storage.get('data').then((data: any) => {
      if (data) {
        this.firebase.database
          .ref(data.id_res_auto + "_" + data.code + "/" + child).set(val);
      }
    });
  };


  getData(segment) {
    return new Promise((resolve, reject) => {

      // this.storage.get('AppLangcode').then((result) => {
      //   if (!result) {
      //     result = 'th';
      //   }
      this.http.get(this.global.baseUrlApi + segment, {
        headers: new HttpHeaders().append('Accept', 'application/json').append('Content-Type', 'application/json')
      })
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          this.presentToast();
          reject(err);
        });
    });
    // });
  }
}
