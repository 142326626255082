import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ServiceReserveService {
  private apiUrl = environment.apiUrl;
  private apiInsertQueue = environment.apiInsertQueue
  slide_path : string = "https://deltafoodpicture.sgp1.digitaloceanspaces.com/banner/";
  showPath_img: string = "https://deltafoodpicture.sgp1.digitaloceanspaces.com/member_img/";
  constructor(

    private http: HttpClient,
    private route: Router,
  ) { }

  private getHeader(){
    const token = localStorage.getItem('tokenUser')
    if (!token) {
     
      return null;
    } else {
      
      return new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
    }
  }

    public verifyUser(code:any) {
  
      const url =`${this.apiUrl}verify`
      const body = {code:code}
      return this.http.post(url,body,)

    }

  public getZone(code: string) {
    const headers = this.getHeader();
    const url = `${this.apiUrl}zone`
    const body = { code: code }

    
    return this.http.post(url, body, {headers});
  }


  insertQueue(id: number, zoneId: number, data: any): Observable<any> {
    const headers = this.getHeader();
 
    
    const url = `${this.apiInsertQueue}reservations-web/res/${id}/zone/${zoneId}`;
    if (!headers) {
      return throwError('No token available')
    }
    return this.http.post(url, data, { headers })
    
  }

  updateQueue(id_res_auto: number, zoneId: number): Observable<any> {
    const headers = this.getHeader();
    if (!headers) {
      return throwError('No token available')
    }
    const url = `${this.apiInsertQueue}dqueue/update-queue/${id_res_auto}/${zoneId}`;
    return this.http.post(url, null, { headers });
  }
}
