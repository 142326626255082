import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class MyApiService {
  private apiUrl = environment.apiUrl;
  // private del = environment.del;
  constructor(
    private http: HttpClient,
    private route: Router,
  ) { }

  public getMyqueue(code: string) {
    const url = `${this.apiUrl}`;
    const body = { code:code };


    return this.http.post(url, body);
}

getNumberQueue(code:any) {
  const url = `${this.apiUrl}wait`;
  const body = { code:code };

  return this.http.post(url,body)
}


decreaseQueue(id_res_auto: number, zoneId: number,code:any,codeRes:any): Observable<any> {
  const body = { id_res_auto: id_res_auto,
                  zone_id: zoneId,
                  code:code,
                  codeRes:codeRes
  };
  console.log(body);
  
  const url = `${this.apiUrl}decrease-queue-web`;
  return this.http.post(url, body)
 
}

modify(data: any) {
  const body = {
    id_res_auto: data.id_res_auto,
    zone_id: data.zone_id,
    code: data.code,
    number:data.number
  };
  console.log(body);
  
  const url = `${this.apiUrl}modify`;
  return this.http.post(url, body);
}

// dAll(){
//   const url = `${this.del}users-reservations/d-all`;
//   return this.http.delete(url);

// }
}
