import { Component, OnInit } from '@angular/core';
import { NavParams, ToastController, NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { WebapiServiceProvider } from '../providers/webapi-service/webapi-service';


@Component({
  selector: 'app-modal-page',
  templateUrl: './modal-page.page.html',
  styleUrls: ['./modal-page.page.scss'],
})
export class ModalPagePage implements OnInit {
  public temp;
  public cart_count = 0;
  public counts = [
    { number: 0 },
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 }
  ];
  public currency = "บาท";
  public currency_shot = '฿';
  public data = { count: 1, name: '',details:[] ,toppings:[],comments:''};
  public lang = 'th';
  public index = -1;
  constructor(
    private router: Router,
    public navCtrl: NavController,
    public navParams: NavParams,
    private s: Storage,
    public modalCtrl: ModalController,
    private api: WebapiServiceProvider,
    private toastCtrl: ToastController,) {
    this.data = navParams.get("data");
    console.log(this.data);
    this.index = navParams.get('index');
  }
  ngOnInit() {
  }
  close() {
    this.modalCtrl.dismiss();
  }

  get_price_sub(del) {
    var price = 0;
    for (let val of del.sub) {
      if (val.selected) {
        price += val.price - 0;
      }
    }
    return price;
  };
  sum_item = function (f) {
    var total_price = f.price - 0;
    for (let val of f.toppings) {
      total_price += (val.price - 0) * val.count;
    };
    for (let detail of f.details) {
      total_price += this.get_price_sub(detail);
    };
    return total_price * f.count;
  };
  check_true(index) {
  }
  t = null;
  selected_change(index, data, d, j) {
    if (this.t !== null) {
      clearTimeout(this.t);
    }

    if (d.number_min - 0 === 1 && d.number_max - 0 === 1) {
      for (let i = 0; i < data.details[index].sub.length; i++) {
        data.details[index].sub[i].selected = false;
      }
      data.details[index].sub[j].selected = true;
    } else {
      data.details[index].sub[j].selected = !data.details[index].sub[j].selected;

      this.t = setTimeout(function () {
        let count_true = 0;
        for (let val of data.details[index].sub) {
          if (val.selected) {
            count_true++;
          }
        }
        if (count_true > data.details[index].number_max) {
          let c = count_true - data.details[index].number_max;
          for (let i = data.details[index].sub.length - 1; i >= 0; i--) {
            if (data.details[index].sub[i].selected) {
              data.details[index].sub[i].selected = false;
              c--;
              if (c === 0) {
                break;
              }
            }
          }
        } else if (count_true < data.details[index].number_min) {
          let c = data.details[index].number_min - count_true;
          for (let i = 0; i < data.details[index].sub.length; i++) {
            if (!data.details[index].sub[i].selected) {
              data.details[index].sub[i].selected = true;
              c--;
              if (c === 0) {
                break;
              }
            }
          }
        }
      }, 1500);
    }
  }
  ionViewDidLoad() {
  }

  addtopping(t) {
    t.count++
    // this.tping.tcount++;

  }
  removetopping(t) {
    if (t.count > 0) {
      t.count--;

    }
  }
  add() {
    this.data.count++;
  }
  remove() {
    if (this.data.count > 1) {
      this.data.count--;
    }
  }

  array_lang(text) {    
    for (let val of text) {
      if (val.shot) {
        if (val.shot.toUpperCase() === this.lang) {
          return val.title;
        }
      }
    }
    return text[0].title;
  }

  add_product(data) {
    if (this.index == -2) {
      console.log(data);
      this.api.postData("change_item", data).then((res) => {
        console.log(data);
        this.api.fb_set("data_pay");
        this.modalCtrl.dismiss();
      });
    } else {
      if (data.status - 0 === 0) {
        // this.translate.get("oos_text").subscribe(oos_text => {
          this.api.Toast("Out of stock");
        // });
      } else {
        var temp = [];
        this.s.get('cart').then((v) => {
          if (!v) { v = []; }
          temp = v;
          if (this.index == -1) {
            let a = this.check_food(data.f_id, temp);
            console.log(a);
            if (a.index === -1) {
              // data.count = 1;
              temp.push(data);
            } else {
              temp[a.index].count = a.count;
            }
            let count = 0;
            for (let i = 0; i < temp.length; i++) {
              count += temp[i].count - 0;
            }
            this.cart_count = count;
            this.s.set('cart', temp);
            // this.translate.get("add").subscribe(add => {
            //   this.translate.get("success").subscribe(success => {
                this.api.Toast( 'Add ' + data.name + " " + 'SUccess');
                this.close();
              // });
            // });
          } else {
            temp[this.index] = data;
            this.s.set('cart', temp);
            this.close();
          }


        });

        //});
      }
    }
  }
  is_change(food) {
    var change = false;
    if (food.toppings.length - 0 !== 0) {
      let count = 0;
      for (let i = 0; i < food.toppings.length; i++) {
        count += food.toppings[i].count - 0;
      }
      if (count !== 0) {
        return true;
      }
    }

    for (let key = 0; key < food.details.length; key++) {
      let val = food.details[key];
      for (let key2 = 0; key2 < val.sub.length; key2++) {
        let val2 = val.sub[key2];
        if (val2.default_val && !val2.selected) {
          change = true;
        } else if (!val2.default_val && val2.selected) {
          change = true;
        }
      };
    };
    if (food.comments !== "") {
      change = true;
    }
    return change;
  };
  check_food(f_id, val) {
    let out = { count: 1, index: -1 };
    for (let key = 0; key < val.length; key++) {
      if (val[key].f_id - 0 === f_id - 0) {
        if (!this.is_change(val[key])) {
          out.count = val[key].count - 0 + 1;
          out.index = key;
        }
      }
    }
    return out;
  };
  onSelectChange(index1) {
  }

}
