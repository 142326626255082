import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServiceReserveService } from './service-reserve/service-reserve.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { MyApiService } from '../c/my-api.service';
// import { QueueSuccessComponent } from '../c/queue-success/queue-success.component';
import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.page.html',
  styleUrls: ['./reservation.page.scss'],
})
export class ReservationPage implements OnInit {

  code: any;
  store: any = { res_name: '' };
  zone: any;
  myqueue:any
  waitNumber:any
  shouldAnimateTransition: boolean = true;
  checkZone: any;
  subgetZone: any
  subVerify: any
  slide_path: string
  showPath_img: string
  subMyqueue:any;
  nodeRealTime: any
  private path_fb: string ='';
  constructor(
    private api: ServiceReserveService,
    private myApiService:MyApiService,
    private activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    private modalCtrl: ModalController,
    private fb: AngularFireDatabase,
  ) { }

  ngOnInit() {
    this.onStart()
    this.showPath_img = this.api.showPath_img
    

  }
  ionViewWillEnter(){
    this. getMyqueue();
    setTimeout(() => {
      this.getNumberResRealtime();
    }, 500);
  }
  ngOnDestroy() {
    this.unsub();
  }
  ionViewDidLeave() {
    this.unsub();
  }

  ionViewWillUnload() {
    this.unsub();
  }
  private unsub() {
    if (this.subgetZone) {
      this.subgetZone.unsubscribe();
    }
    if (this.subVerify) {
      this.subVerify.unsubscribe();
    }
    if( this.nodeRealTime){
      this.nodeRealTime.unsubscribe();
    }
    if( this.subMyqueue){
    this.subMyqueue.unsubscribe();

    }
  }

  handleRefresh(e: any) {
    this.verifyUser(this.code)
    setTimeout(() => {
      this.onStart()
      e.target.complete();
    }, 2000);
  }

  onStart() {
    this.activatedRoute.paramMap.subscribe((queryParams: any) => {
      this.code = queryParams.params.code
      this.verifyUser(this.code)
      localStorage.setItem('res_code', this.code)


    });
  }
  verifyUser(code: any) {
    this.subVerify = this.api.verifyUser(code).subscribe(
      (res: any) => {
        if (res.success) {

          localStorage.setItem('tokenUser', res.token)
          this.getZone(code);
        } else {
          console.log('ขอ jwt ล้มเหลว');

        }

      },
      (err: any) => {
        console.log(err);

      }
    )
  }

  getZone(code: string) {


    this.subgetZone = this.api.getZone(code).subscribe(
      (res: any) => {
        this.checkZone = res?.res?.res_zone_number;
        this.store = res?.res;
        this.zone = res?.zone;


        const selectedZone = this.zone.find(zone => zone.zone_id === this.zone[0].zone_id);


        if (!selectedZone) {
          console.error(`Zone with zone_id ${this.zone[0].zone_id} not found`);
          return;
        }

        // if (this.checkZone === 1) {


        //   const navigationExtras: NavigationExtras = {
        //     state: {
        //       store: this.store,
        //       zone_id: this.zone[0].zone_id,
        //       zone_select: selectedZone,
        //       code: this.code
        //     }
        //   };



        //   this.navCtrl.navigateForward(['r', this.code, 'resturant'], navigationExtras);
        // } else {
        //   return;
        // }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  goToSelectQ(zone_id: number) {
    const selectedZone = this.zone.find(zone => zone.zone_id === zone_id);


    if (!selectedZone) {
      console.error(`Zone with zone_id ${zone_id} not found`);
      return;
    }

    const navigationExtras: NavigationExtras = {
      state: {
        store: this.store,
        zone_id: zone_id,
        zone_select: selectedZone,
        code: this.code
      }
    };


    this.navCtrl.navigateForward(['r', this.code, 'resturant'], navigationExtras);
  }

 getNumberResRealtime() {

    
    this.nodeRealTime = this.fb
      .object(`${this.code }/`+ 'zone')
      .valueChanges()
      .subscribe((data: any) => {
        if (data !== null) {
          this.getMyqueue();


        }
      });
  }
  getMyqueue() {
    const codeMyqueue = localStorage.getItem('myqueue');
    this.subMyqueue = this.myApiService.getMyqueue(codeMyqueue).subscribe(
      (response) => {
        this.myqueue = response;
      
      
        
        const code = this.myqueue?.reservation?.code;
        const dataShowModal = this.myqueue?.reservation?.user_status
        localStorage.setItem('myqueue',code);

        // this.modalQueueSuc(this.myqueue)
        this.getNumberQ(code);
        // this.getNumberResRealtime();

        if (dataShowModal !== 1) {
          localStorage.removeItem('modal_shown');
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  selectMyqueue(mycode:any){
 
    this.navCtrl.navigateForward(['c',mycode]);
  }
  getNumberQ(code: any) {


    this.myApiService.getNumberQueue(code).subscribe(
      (number: any) => {
        this.waitNumber = number.queueToWait;
      },
      (error) => {
        console.error('Error fetching user data:', error);
      }
    );
  }
  // async modalQueueSuc(data: any) {
  //   const user = data.reservation.name_user;
  //   const fomatData = data
  //   const activeShow = fomatData?.reservation?.user_status
  //   let getStatusOnStorage: any = localStorage.getItem('user_status');
  //   const modalShow = localStorage.getItem('modal_shown');

  //   if (modalShow) {
  //     return;
  //   }
  //   if (activeShow == 1) {
  //     if (activeShow === 1) {
  //       localStorage.setItem('modal_shown', 'true');
  //       const modal = await this.modalCtrl.create({
  //         component: QueueSuccessComponent,
  //         cssClass: 'suc-modal',
  //         componentProps: {
  //           myqueue: fomatData,
  //           user: user
  //         },
  //       });

  //       await modal.present();

  //     }
  //   }

  // }
}