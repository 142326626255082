import { Component, OnInit, ɵConsole, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { WebapiServiceProvider } from '../providers/webapi-service/webapi-service';
import { EditDataPage } from '../edit-data/edit-data.page';
// import { SwPush } from '@angular/service-worker';
declare var liff: any;
@Component({
  selector: 'app-my-queue-detail',
  templateUrl: './my-queue-detail.page.html',
  styleUrls: ['./my-queue-detail.page.scss'],
})
export class MyQueueDetailPage implements OnInit {
  public data = {
    count: '-',
    date_time: "2020-06-08 20:26:53",
    people: 1,
    qcode: "-",
    status: 0,
    table: "",
    url: "",
    name: '',
    phone: '',
    q_id: ''
  };
  show_queue = false;
  queue_all = '-';
  code: string = '';
  public user: any = { mm_id: '0' };

  readonly VAPID_PUBLIC_KEY = "BE2RlfIUa3CdcutBcVuTZhduE8AHxPoeM37cTQ0pF6G7h7sdAsan1dCp4ww_HQnUoqHY2tf_RuvMO9o6TGFTAQ8";

  // {"publicKey":"BE2RlfIUa3CdcutBcVuTZhduE8AHxPoeM37cTQ0pF6G7h7sdAsan1dCp4ww_HQnUoqHY2tf_RuvMO9o6TGFTAQ8","privateKey":"QsltbpPMJv0V6_APHVBkrHxdTL537Mxo4WLdHDksCBE"}

  public store = {
    code: "",
    add_food_inq: false,
    id_res_auto: '2',
    cus_cancel: true,
    fb: "",
    address: '',
    name: "Deltafood",
    order_id: 0,
    line_settings: { liff_queue: '', lineid: '' },
    website: 'https://deltafood.me',
    logo_url: 'member_img/member_1678936883_.jpg',
    qrcode_text: { buttom: '' }
  };
  isAlertOpen = false;
  public alertButtons = ['OK'];
  constructor(
    public afd: AngularFireDatabase,
    public alertController: AlertController,
    public http: HttpClient,
    public toastController: ToastController,
    private ref: ChangeDetectorRef,
    public modalController: ModalController,
    public translate: TranslateService,
    public api: WebapiServiceProvider,
    private route: ActivatedRoute,
    public router: Router) {
    translate.setDefaultLang('th');
  }
  async open_group() {

  }
  public load_member = true;
  async confirm_login() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'ล็อกอินผ่านไลน์',
      message: 'เพื่อรับสิทธิพิเศษจากทางร้าน และสมัครเป็นสมาชิก',
      buttons: [
        {
          text: 'ปิดออก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'ยืนยัน',
          handler: () => {
            liff.login();
          }
        }
      ]
    });
    await alert.present();
  }
  async not_have_code() {
    localStorage.removeItem('queue_data');
    localStorage.removeItem("queue");
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'ไม่พบข้อมูล',
      // subHeader: 'Subtitle',
      // message: 'This is an alert message.',
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    setTimeout(() => {
      window.location.href = 'https://deltafood.me';
    }, 2000);

  }
  async not_have() {
    localStorage.removeItem('queue_data');
    localStorage.removeItem("queue");
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คิวของคุณถูกยกเลิก หรือผ่านมาแล้ว',
      // subHeader: 'Subtitle',
      // message: 'This is an alert message.',
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    window.location.href = 'https://deltafood.me';
  }
  async getUserProfile(data, q_id) {
    const profile: any = await liff.getProfile();
    profile.id_res_auto = data.id_res_auto;
    profile.q_id = q_id;
    this.api.postData('update_profile', profile).then((res: any) => {
      this.user = res;
      if (this.user.follow - 0 == 0 && data.line_settings.lineid != '') {
        this.follow(data.line_settings.lineid);
      }
      setTimeout(() => {
        this.ref.detectChanges();
      }, 1000);
    });

  }
  async follow(line_id) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คุณยังไม่ได้ติดตามผ่าน LINE OA ของร้าน',
      message: 'กรุณากดติดตาม เพื่อรับการแจ้งเดือนเมื่อถึงคิว และรับโปรโมชั่นพิเศษจากทางร้าน',
      buttons: [
        {
          text: 'ปิดออก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'ยืนยัน',
          handler: () => {
            liff.openWindow({
              url: decodeURIComponent("https://line.me/R/ti/p/" + line_id),
              external: true
            });
          }
        }
      ]
    });

    await alert.present();
  }
  openMenu() {
    // this.api.getData("get_res/" + this.store.id_res_auto).then((result: any) => {
    //   console.log(result);
    // });
  }
  rand = 0;

  load(): void {
    let q_id = localStorage.getItem("queue");
    if (q_id && q_id != '0') {
      this.api.getData("get_queue/" + q_id + '/' + this.store.id_res_auto).then((result: any) => {
        if (result.q_id - 0 == 0) {
          this.show_queue = false;
          this.route.paramMap.subscribe((params: any) => {
            if (!params.params.id) {
              this.not_have();
            }
          });
        } else {
          this.show_queue = true;
        }
        this.data = result;
        this.queue_all = result.queue_all;
        this.rand = Math.random();
        if (this.data.status - 0 == 0) {
          if (!this.store.line_settings.liff_queue) {
            this.store.line_settings.liff_queue = '';
          }
          if (this.store.line_settings.liff_queue != '') {

            if (result.member_id == '0') {
              if (this.load_member) {
                this.load_member = false;
                liff.init({ liffId: this.store.line_settings.liff_queue }, () => {
                  if (liff.isInClient() || liff.isLoggedIn()) {
                    this.getUserProfile(this.store, result.q_id);
                  } else {
                    this.confirm_login();
                  }
                });
              }
            } else {
              this.user = result.member;
              if (this.user.follow - 0 == 0) {
                this.follow(this.store.line_settings.lineid);
              }
            }
          } else {
            if (this.data.name == '' || this.data.phone == '') {

              this.edit_modal(this.code);
            }
          }
        } else if (this.data.status - 0 == 1) {

          this.ready(result.table);


        } else if (this.data.status - 0 == 2) {

          this.cancel();
        } else if (this.data.status - 0 == 3) {
          localStorage.removeItem("queue");
          window.location.reload();
          // window.location.href = this.store.website;
          // this.noti();
          // this.ready(result.table);
        }
        setTimeout(() => {
          this.ref.detectChanges();
        }, 1000);
      });
    }
  }
  flag = true;
  async cancel_q() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คุณต้องการยกเลิกคิวของคุณหรือไม่',
      // subHeader: 'Subtitle',
      // message: 'This is an alert message.',
      buttons: [
        {
          text: 'ปิดออก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'ยกเลิกคิว',
          handler: (value) => {
            let q = localStorage.getItem("queue");
            this.flag = false;
            this.api.postData("cancel", { q: q }).then(() => {

              this.api.update_json('queue', this.store.id_res_auto);

              // this.load();


              // this.afd.database.ref(this.store.fb + "/queue").set(Math.floor(Math.random() * 100) + 1);
              this.afd.database.ref(this.store.fb + "/noti").set(Math.floor(Math.random() * 100) + 1);


              this.cancel_success();
            })
          }
        }
      ]
    });

    await alert.present();
  }
  async cancel_success() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'โอกาสหน้าแวะมาใหม่นะคะ',
      // subHeader: 'Subtitle',
      // message: 'This is an alert message.',
      buttons: [
        {
          text: 'ปิดออก',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // window.location.href = this.store.website;
          }
        }
      ]
    });
    setTimeout(() => {
      localStorage.removeItem('queue');
      setTimeout(() => {

        window.location.href = this.store.website;
      }, 100);

    }, 5000);
    await alert.present();
  }


  noti() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {

    } else {
      window.navigator.vibrate([500, 250, 500, 250, 500, 250]);
    }


  }
  async ready(table) {
    // const alert = await this.alertController.create({
    //   cssClass: 'my-custom-class',
    //   header: 'ถึงคิวคุณแล้ว เชิญที่ ' + table,
    //   // subHeader: 'กรุณาสแกนเพื่อจองคิวใหม่',
    //   // message: 'This is an alert message.',
    //   buttons: [
    //     {
    //       text: 'ตกลง',
    //       handler: () => {
    //       }
    //     }
    //   ]
    // });
    this.noti();
    setTimeout(() => {
      this.router.navigate(['ready']);
    }, 3000);
    // await alert.present();
  }
  async cancel() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'คิวของคุณถูกยกเลิกแล้ว',
      subHeader: 'กรุณาสแกนเพื่อจองคิวใหม่',
      // message: 'This is an alert message.',
      buttons: ['OK']
    });
    localStorage.removeItem('queue');
    setTimeout(() => {
      window.location.href = this.store.website;
    }, 3000);
    await alert.present();
  }
  handleRefresh(e: any) {
    this.load();
    setTimeout(() => {
      // Any calls to load data go here
      e.target.complete();
    }, 1000);
  }

  async edit_modal(code: string) {
    let queeu_data = localStorage.getItem("queue_data");
    let q = { name: '', phone: '', q_id: '0', people: 1 };
    if (queeu_data) {
      q = JSON.parse(queeu_data);
    }
    if (this.data.name != '') {
      q.name = this.data.name;
    }
    if (this.data.phone != '') {
      q.phone = this.data.phone;
    }
    if (this.data.people != 0) {
      q.people = this.data.people;
    }
    let q_id = localStorage.getItem("queue");
    if (q_id) {
      q.q_id = q_id;
    }

    const modal = await this.modalController.create({
      component: EditDataPage,
      cssClass: 'edit_data',
      componentProps: {
        data: q,
        code: code,
        id_res_auto: this.store.id_res_auto
      }
    });
    modal.onDidDismiss().then((data: any) => {

      if (data.role == 'backdrop') {
        this.edit_modal(code);
      } else {
        this.router.navigate(['/home']);

        this.load();
        setTimeout(() => {
          // window.location.reload();
        }, 500);
      }

    }, () => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
    return await modal.present();
  }
  ngOnInit(): void {

    this.route.paramMap.subscribe((params: any) => {
      let storage: any = localStorage.getItem('queue_data');
      let queue = localStorage.getItem("queue");
      if (params.params.id != undefined) {
        this.api.getData("get_store/" + params.params.id).then((store: any) => {
          this.code = params.params.id;
          if (store.flag) {
            this.store = store;
            // console.log(this.store);
            if (store.queue_version && store.queue_version == 2) {
              window.location.href = 'https://queue.deltafood.me/r/' + store.code_v2
            }
            this.afd.database.ref(this.store.fb + "/queue").on("value", (val) => {
              if (this.flag) {
                let storage: any = localStorage.getItem('queue_data');
                let queue = localStorage.getItem("queue");
                if (storage && queue) {
                  this.router.navigate(['/home']);
                } else {
                  this.edit_modal(this.code);
                }
              }

            });
          } else {
            this.not_have_code();
          }
        });
      } else if (storage && queue) {
        storage = JSON.parse(storage);
        this.code = storage.code;
        if (storage.code == undefined) {
          // window.location.href = 'https://deltafood.me';
        }
        this.api.getData("get_store/" + storage.code).then((store: any) => {
          if (store.flag) {
            this.store = store;
            if (store.queue_version && store.queue_version == 2) {
              window.location.href = 'https://queue.deltafood.me/r/' + store.code_v2
            }
            this.afd.database.ref(this.store.fb + "/queue").on("value", (val) => {
              if (this.flag) {
                if (params.params.id) {
                  this.router.navigate(['/home']);
                } else {
                  this.load();
                }

              }

            });
          } else {
            // window.location.href = "https://deltafood.me";
          }
        });
        let queue = localStorage.getItem("queue");
        if (!queue) {
          if (params.params.id) {
            this.edit_modal(this.code);
          }
        }
      } else {
        if (params.params.id == undefined) {
          window.location.href = 'https://deltafood.me';
        }

        let queue = localStorage.getItem("queue");
        if (!queue) {
          this.edit_modal(params.params.id);
        }

      }
    });
  }
  doRefresh(event) {
    this.load();
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

}
